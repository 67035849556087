export const fiscalSessionTypes = [
  { id: 1, name: "AD" },
  { id: 2, name: "BS" },
];

export const genders = [
  { id: "m", name: "Male" },
  { id: "f", name: "Female" },
  { id: "o", name: "Others" },
];

export const statusList = [
  {
    id: 1,
    value: "PENDING",
  },
  {
    id: 3,
    value: "CANCELLED",
  },
];

export const purchaseTypes = [
  { name: "Direct", value: "DIRECT" },
  { name: "Protocol", value: "PROTOCOL" },
];

export const paymentMethods = [
  { name: "Cash", value: "CARD" },
  { name: "Bank Transfer", value: "BANK_TRANSFER" },
  { name: "Cash", value: "CASH" },
  { name: "Cheque", value: "CHEQUE" },
  { name: "Other", value: "OTHER" },
];

export const returnReasons = [
  { name: "Damaged", value: "DAMAGED" },
  { name: "Wrong Item", value: "WRONG_ITEM" },
  { name: "Overstock", value: "OVERSTOCK" },
  { name: "Other", value: "OTHER" },
];

export const deliveryStatuses = [
  { name: "Pending", value: "PENDING" },
  { name: "In Transit", value: "IN_TRANSIT" },
  { name: "Delivered", value: "DELIVERED" },
  { name: "Delayed", value: "DELAYED" },
  { name: "Cancelled", value: "CANCELLED" },
];

export const shippingModes = [
  { name: "Air", value: "AIR" },
  { name: "Land", value: "LAND" },
  { name: "Water", value: "WATER" },
];

export const washedTypes = [
  { name: "Washed", value: "WASHED" },
  { name: "Non-Washed", value: "NON_WASHED" },
];
export const excludeCreateBtn = ["inspection"];
export const hideButton = ["organization", "organizationHierarchy"];

export const itemTypes = [
  { name: "Synthetic", value: "SYNTHETIC" },
  { name: "Natural", value: "NATURAL" },
];
