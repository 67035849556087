import { BiPurchaseTag } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { RiSettings4Line } from "react-icons/ri";

export const sidebarData = [
  {
    menu: "Issues",
    icon: <BiPurchaseTag size={20} />,
    key: "issue",
    link: "/ticket-issues",
    permissions: [
      "can_create_issue",
      "can_read_issue",
      "can_update_issue",
      "can_delete_issue",
      "can_create_cancel_issue",
      "can_read_cancel_issue",
      "can_update_cancel_issue",
      "can_delete_cancel_issue",
      "can_create_reply",
      "can_read_reply",
      "can_update_reply",
      "can_delete_reply",
    ],
  },
  {
    menu: "Members",
    icon: <FaUsers size={20} />,
    key: "members",
    link: "/members",
    permissions: ["can_create_member", "can_read_member", "can_update_member", "can_delete_member"],
  },

  // {
  //   menu: "Executive Members",
  //   icon: <FaUsersGear size={20} />,
  //   key: "executivemembers",
  //   link: "/executive-members",
  //   permissions: [""],
  // },
  // {
  //   menu: "Office Activities",
  //   icon: <LuLayoutDashboard size={20} />,
  //   link: "/office-activity",
  //   permissions: ["view_dyeing", "update_dyeing", "add_dyeing"],
  //   key: "office",
  // },
  // {
  //   menu: "Other Activities",
  //   icon: <MdOutlineSpaceDashboard size={20} />,
  //   link: "/other-activity",
  //   permissions: ["view_weaving", "update_weaving", "add_weaving"],
  //   key: "other",
  // },
];

export const admin = [
  {
    menu: "Setting",
    icon: <RiSettings4Line size={18} />,
    permissions: ["can_create_customgroup"],
    sub_menu: [
      {
        name: "System Setup",
        link: "/system-setup",
        key: "coreSetup",
        permissions: ["can_create_customgroup"],
        child_menu: [
          {
            name: "Organization",
            link: "/organization-setup",
            permissions: [""],
          },
          {
            name: "Sector",
            link: "/sector",
            permissions: ["can_create_sector", "can_read_sector", "can_update_sector", "can_delete_sector"],
          },
        ],
      },
      {
        name: "User Setup",
        link: "/user-setup",
        key: "userSetup",
        permissions: ["can_create_customgroup"],
        child_menu: [
          {
            name: "User",
            link: "/user",
            permissions: ["can_create_user", "can_read_user", "can_update_user", "can_delete_user"],
          },
          {
            name: "User Group",
            link: "/user-group",
            permissions: [
              "can_create_customgroup",
              "can_read_customgroup",
              "can_update_customgroup",
              "can_delete_customgroup",
            ],
          },
        ],
      },
    ],
  },
];
