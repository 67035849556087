import React, { lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import PageNotFound from "../Components/PageNotFound";
import Sectors from "../Pages/Sector/Pages";
import ProtectedRoute from "./ProtectedRoute";

//for refreshing the page when lazy fails loading the component
const lazyWithReload = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("page-has-been-force-refreshed") || "false"
    );
    try {
      const component = await componentImport();
      window.localStorage.setItem("page-has-been-force-refreshed", "false");
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem("page-has-been-force-refreshed", "true");
        return window.location.reload();
      }
      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      throw error;
    }
  });

const OrganizationSetupAlert = lazyWithReload(() => import("../Components/Alert/OrganizationSetupAlert"));

// core app
const FiscalSessionAD = lazyWithReload(() => import("../Pages/FiscalSessionAD/Page"));
const FiscalSessionBS = lazyWithReload(() => import("../Pages/FiscalSessionBS/Page"));
const Country = lazyWithReload(() => import("../Pages/Country/Page"));
const Organization = lazyWithReload(() => import("../Pages/Organization/Page"));
const Dashboard = lazyWithReload(() => import("../Pages/Dashboard/Pages"));
const CoreSetupTabs = lazy(() => import("../Components/Tab/CoreSetupTab"));

// Routes
const UserListing = lazyWithReload(() => import("../Pages/User/Page"));
const UserGroupListing = lazyWithReload(() => import("../Pages/UserGroup/Page"));
const ChangePassword = lazyWithReload(() => import("../Pages/User/ResetPassword/ChangePassword"));

const Issues = lazyWithReload(() => import("../Pages/Issues/Pages"));
const Members = lazyWithReload(() => import("../Pages/Members/Pages"));
const OfficeActivities = lazyWithReload(() => import("../Pages/OfficeActivities/Pages"));
const OtherActivities = lazyWithReload(() => import("../Pages/OtherActivities/Pages"));

const PrivateRoute = () => {
  const { isSetupDone } = useSelector((state) => state.auth);

  const ErrorFallback = ({ error }) => {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={""}>
        {isSetupDone ? (
          <Switch>
            <ProtectedRoute exact path="/" component={Dashboard} permission={""} />
            <ProtectedRoute exact path="/change-password" component={ChangePassword} permission={""} />

            {/* <ProtectedRoute exact path="/core-setup" component={CoreSetupTabs} permission={permission?.coreSetup} /> 
            <ProtectedRoute exact path="/user-setup" component={UserTabs} permission={permission?.userSetup} />*/}
            <ProtectedRoute exact path="/change-password" component={ChangePassword} permission={""} />

            {/* system setup */}
            {/* <ProtectedRoute path="/fiscalSessionBS" component={FiscalSessionBS} permission={""} />
            <ProtectedRoute path="/fiscalSessionAD" component={FiscalSessionAD} permission={""} /> */}

            {/* <ProtectedRoute path="/country" component={Country} permission={""} /> */}
            <ProtectedRoute path="/organization-setup" component={Organization} permission={""} />
            <ProtectedRoute
              path="/sector"
              component={Sectors}
              permission={["can_create_sector", "can_read_sector", "can_update_sector", "can_delete_sector"]}
            />
            {/* user setup */}
            <ProtectedRoute
              exact
              path="/user"
              component={UserListing}
              permission={["can_create_user", "can_read_user", "can_update_user", "can_delete_user"]}
            />
            <ProtectedRoute
              exact
              path="/user-group"
              component={UserGroupListing}
              permission={[
                "can_create_customgroup",
                "can_read_customgroup",
                "can_update_customgroup",
                "can_delete_customgroup",
              ]}
            />

            <ProtectedRoute
              exact
              path="/ticket-issues"
              component={Issues}
              permission={[
                "can_create_issue",
                "can_read_issue",
                "can_update_issue",
                "can_delete_issue",
                "can_create_cancel_issue",
                "can_read_cancel_issue",
                "can_update_cancel_issue",
                "can_delete_cancel_issue",
                "can_create_reply",
                "can_read_reply",
                "can_update_reply",
                "can_delete_reply",
              ]}
            />
            <ProtectedRoute
              exact
              path="/members"
              component={Members}
              permission={["can_create_member", "can_read_member", "can_update_member", "can_delete_member"]}
            />
            {/* <ProtectedRoute exact path="/executive-members" component={ExecutiveMembers} permission={""} />
            <ProtectedRoute exact path="/office-activity" component={OfficeActivities} permission={""} />
            <ProtectedRoute exact path="/other-activity" component={OtherActivities} permission={""} /> */}

            <Route path="*" component={PageNotFound} />
          </Switch>
        ) : (
          // <OrganizationSetupAlert />
          <Switch>
            <ProtectedRoute path="/organization-setup" component={Organization} permission={""} />
            <Route path="*" component={OrganizationSetupAlert} />
          </Switch>
        )}
      </Suspense>
    </ErrorBoundary>
  );
};

export default withRouter(PrivateRoute);
