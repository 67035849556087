import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../Redux/Auth/authSlice";
import alertReducer from "../Redux/Alert/alertSlice";
import layoutReducer from "../Redux/Layout/layoutSlice";
import tabsValueReducer from "../Redux/TabsValue/tabsValueSlice";

import organizationReducer from "../Pages/Organization/Redux/organizationSlice";

import countryReducer from "../Pages/Country/Redux/countrySlice";

import fiscalSessionADReducer from "../Pages/FiscalSessionAD/Redux/fiscalSessionADSlice";
import fiscalSessionBSReducer from "../Pages/FiscalSessionBS/Redux/fiscalSessionBSSlice";
import userGroupReducer from "../Pages/UserGroup/Redux/userGroupSlice";
import userReducer from "../Pages/User/Redux/userSlice";


import ticketIssueReducer from "../Pages/Issues/Redux/ticketIssuesSlice.js";
import membersReducer from "../Pages/Members/Redux/membersSlice.js";
import sectorReducer from "../Pages/Sector/Redux/sectorSlice";
import executiveMembersReducer from "../Pages/ExecutiveMembers/Redux/executiveMemberSlice";
import officeActivitiesReducer from "../Pages/OfficeActivities/Redux/officeActivitiesSlice";
import otherActivitiesReducer from "../Pages/OtherActivities/Redux/otherActivitiesSlice";

import systemSelectionReducer from "../Redux/SystemSelection/systemSelectionSlice";

import notificationReducer from "../Components/CommonPageHeader/redux/notificationSlice.js";

// reducers

const rootReducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  layout: layoutReducer,
  tabsValue: tabsValueReducer,
  systemSelection: systemSelectionReducer,

  organization: organizationReducer,
  sectors: sectorReducer,

  country: countryReducer,

  ad: fiscalSessionADReducer,
  bs: fiscalSessionBSReducer,
  user: userReducer,
  userGroup: userGroupReducer,

  notification: notificationReducer,

  // reducer
  ticketIssues: ticketIssueReducer,
  members: membersReducer,
  executiveMembers: executiveMembersReducer,
  officeActivities: officeActivitiesReducer,
  otherActivities: otherActivitiesReducer,
});

export default rootReducer;
